import React from "react"; 
import me from "../photos/me.jpeg"; 
import LI from "../photos/linkedin.svg";
import EI from "../photos/emailIcon.png"; 
import GI from "../photos/github.svg";
import "./HomeStyle.css";
import "../components/displayBox.css"; 

function Box({ children, ...props }) {
  return <div {...props}>{children}</div>
}

function Home() {
  return (
    <div id="Home" >
    <div id="photo">
      <img src={me} alt="me" className="profileImg" />
    </div>
    <br />
    <Box className="boxH">
    <h2 className="center" style={{ margin: 5, fontSize: 30,  color: 'rgb(194, 125, 15)',}}>Marina Rosenwald</h2>
      <p style={{margin: 8, fontSize: '1.4rem', }}>

      My name is Marina Rosenwald. By day, I am a Machine Learning Engineer. By night, I am dedicated to advancing my knowledge as a Master's student in Computer Science and Software Engineering, with a focus on Artificial Intelligence and Machine Learning.
      <br></br><br></br>
      Driven by an insatiable curiosity for technology, I strive to leverage my expertise in AI/ML to address complex, real-world challenges and contribute meaningfully to the technological landscape.
      <br></br><br></br>
      Beyond academia and work, I enjoy exploring the Pacific Northwest's diverse trails, engaging in snowboarding adventures, and experimenting with new recipes in the kitchen. I am also passionate about volunteering with nonprofits that aim to make the outdoors more accessible for individuals with disabilities.
      <br></br><br></br>
      I am excited about the endless possibilities technology offers and am eager to contribute to the future of Machine Learning.
      </p>
    </Box>

    <br />

    <Box className="boxH">
      <div id="contact">
      <h2 style={{color: 'rgb(194, 125, 15)',}}>
        Contact me
      </h2>
      </div>
    </Box>

    <br />
    <div className="row">

    <div className="column">
        <Box className="boxList">
        <a href='https://www.linkedin.com/in/marina-rosenwald/'>
            <div className='DinsideBox'>
                  <img src={ LI } alt="LinkedInIcon" className="Dicon"></img>
              <div className="DinsideText">
              <p style={{color: 'rgb(194, 125, 15)', fontSize: '1.4rem', }} >LinkedIn</p>
              </div>
            </div>
            </a>
        </Box>
      </div>
        
      <div className="column">
        <Box className="boxList">
            <div className='DinsideBox'>
              <img src={ EI } alt="emailIcon" className="Dicon"></img>
              <div className="DinsideText">
                <p style={{color: 'rgb(194, 125, 15)', fontSize: '1.4rem', }}>marinarosenwald@gmail.com</p>
              </div>
            </div>
        </Box>
      </div>
        
      <div className="column">
        <Box className="boxList">
        <a href='https://github.com/marinarosenwald'>
            <div className='DinsideBox'>
              <img src={ GI } alt="githubIcon" className="Dicon"></img>
              <div className="DinsideText">
                <p style={{color: 'rgb(194, 125, 15)', fontSize: '1.4rem', }}>GitHub</p>
              </div>
            </div>
          </a>
        </Box>
      </div>

    </div>

  </div>
    
  )
}

export default Home;
